// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TheBottomNavbar_Ke3zu{background-color:#fff;bottom:0;display:none;height:0;left:0;position:fixed;width:100%;z-index:100}@media(max-width:1279.98px){.TheBottomNavbar_Ke3zu{display:block}}.list_VddAO{align-items:center;display:flex;height:100%;padding:0 1.2rem;width:100%}.item_UneqK{flex:auto;height:100%}.link_fTYoa{align-items:center;display:flex;flex-direction:column;font-size:1.1rem;font-weight:500;height:100%;justify-content:center;transition:color .3s ease;width:100%}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.link_fTYoa:hover{color:#855aff}}.wrapper_LPrgD{display:flex;margin-bottom:.8rem;position:relative}.counter_Avo6L{align-items:center;background-color:#855aff;border:2px solid #fff;border-radius:.4rem;color:#fff;display:flex;font-size:1.1rem;font-weight:700;justify-content:center;line-height:1;padding:.4rem;position:absolute;right:0;top:0;transform:translate(60%,-40%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"TheBottomNavbar": "TheBottomNavbar_Ke3zu",
	"list": "list_VddAO",
	"item": "item_UneqK",
	"link": "link_fTYoa",
	"wrapper": "wrapper_LPrgD",
	"counter": "counter_Avo6L"
};
module.exports = ___CSS_LOADER_EXPORT___;
