// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VDropdown_HTwNq{display:block;position:relative;transition:background-color .3s ease;-webkit-user-select:none;user-select:none}.VDropdown--medium_0qGAJ .label_SMQjF{padding:1.4rem 1.8rem}.VDropdown--medium_0qGAJ .text_DMbWr{font-size:1.5rem;letter-spacing:-.0225rem;line-height:1.6rem;text-transform:lowercase}.VDropdown--default_TzqEh .label_SMQjF{color:#17181c}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.VDropdown--default_TzqEh:hover .label_SMQjF{color:#73788c}}.VDropdown--default_TzqEh.is-opened_drIsh .label_SMQjF{color:#73788c}.VDropdown--default_TzqEh.is-disabled_Zr-wc{pointer-events:none}.VDropdown--default_TzqEh.is-disabled_Zr-wc .label_SMQjF{color:#73788c}.VDropdown_HTwNq.is-opened_drIsh .arrow_cNQIx{transform:rotate(180deg)}.VDropdown_HTwNq.is-shadow_vNTi2 .menu_Cf1fs{box-shadow:#73788c}.VDropdown_HTwNq.is-full-width_n3jMU .label_SMQjF{justify-content:space-between;width:100%}.VDropdown_HTwNq.is-rightPosition_kxUsa .menu_Cf1fs{left:auto;right:0}.label_SMQjF{align-items:center;color:currentcolor;cursor:pointer;display:inline-flex;width:100%}.text_DMbWr{transition:color .3s ease;white-space:nowrap}.arrow_cNQIx{line-height:0;margin-left:.4rem;z-index:1;fill:currentcolor;transform:translateZ(0);transition:all .3s ease}.menu_Cf1fs{left:0;position:absolute;top:100%;z-index:17}.menu_Cf1fs.select-menu-enter-active{transition:opacity .3s ease-in-out,transform .3s ease-in-out}.menu_Cf1fs.select-menu-leave-active{transition:opacity .15s ease-in-out,transform .15s ease-in-out}.menu_Cf1fs.select-menu-enter,.menu_Cf1fs.select-menu-leave-active{opacity:0;transform:translateY(16px)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"VDropdown": "VDropdown_HTwNq",
	"VDropdown--medium": "VDropdown--medium_0qGAJ",
	"label": "label_SMQjF",
	"text": "text_DMbWr",
	"VDropdown--default": "VDropdown--default_TzqEh",
	"is-opened": "is-opened_drIsh",
	"is-disabled": "is-disabled_Zr-wc",
	"arrow": "arrow_cNQIx",
	"is-shadow": "is-shadow_vNTi2",
	"menu": "menu_Cf1fs",
	"is-full-width": "is-full-width_n3jMU",
	"is-rightPosition": "is-rightPosition_kxUsa"
};
module.exports = ___CSS_LOADER_EXPORT___;
